import React from "react";
import thumbTwo from "../../../assets/images/client-logo/nfi.png";
import thumbOne from "../../../assets/images/client-logo/erasmus.png";
import logo from "../../../assets/images/client-logo/victron.png";

export default function MainSection() {
  return (
    <section className="about-section style-four py-120 rpy-100">
      <div className="container rpb-95">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-content pr-90 rpr-0 rmb-55 wow fadeInLeft delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">Who are we looking for</span>
                <h2>Do you want to optimize or grow your activities?</h2>
              </div>
              <p>
                You are at least a medium sized organization (this could be a company or a division/department) with +10M euro revenue.<br />
                The organization has a strong growth or optimization ambition and has:
              </p>
                <ul className="list-style-three">
                  <li>experience with IT projects,</li>
                  <li>some in house IT systems and preferably IT minded professionals</li>
                  <li>IT investments as a permanent item on the budget.</li>
                </ul>
              <p>
                Our ideal customer knows that working with IT is an agile process and understands that all tools have their time and phase.<br />
                You are looking for:
              </p>
                <ul className="list-style-three">
                  <li>iterative improvements</li>
                  <li>adapting continuously based on progressive insights</li>
                  <li>durable and high quality solutions</li>
                  <li>a potential long term collaboration.</li>
                </ul>
              <p>
                We offer:
              </p>
                <ul className="list-style-three">
                  <li>An experienced team of senior developers 10+ years of experience</li>
                  <li>IT business alignment</li>
                  <li>Unlocking your growth potential with IT</li>
                  <li>more vacation days, because we take care of your automation :)</li>
                </ul>
              <p>
                Do you think you are a good match for us:
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-four-images text-center wow fadeInRight delay-0-2s">
              <div className="row">
                <div className="col-6">
                  <img src={thumbOne} alt="About" />
                  <img
                    className="about-bg-circle d-block mx-auto"
                    src="assets/images/about/about-circle-dots.png"
                    alt="About"
                  />
                </div>
                <div className="col-6">
                  <img
                    className="bg-circle-dtos mb-35 d-block mx-auto"
                    src={logo}
                    alt="Logo"
                  />
                  <img src={thumbTwo} alt="About" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
