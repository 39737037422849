import React from "react";
import InnerPageDefaultLayout from "../../helpers/InnerPageDefaultLayout";
import MainSection from "./MainSection";

export default function AboutFour() {
  return (
    <InnerPageDefaultLayout
      pageTitle="Work with us"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Work with us" },
      ]}
    >
      <MainSection />
    </InnerPageDefaultLayout>
  );
}
