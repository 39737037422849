import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import background from "../../../assets/images/contact/home-two.jpg";
import http from '../../../services/http';

export default function GetInTouchSection() {
  const location = useLocation();
  const [messageSent, setMessageSent] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [messageInput, setMessageInput] = useState('');

  useEffect(() => {
    if (location.hash && location.hash === '#contact') {
      document.querySelector('.contact-four').scrollIntoView({behavior: "smooth"})
    }
  }, [location]);

  const onContactFormHandler = (event) => {
    event.preventDefault();

    const data = {
      name: nameInput,
      email_address: emailInput,
      phone_number: phoneInput,
      body: messageInput,
    }

    http.post('/api/contact_form/', data);

    setMessageSent(true);
    setNameInput('');
    setPhoneInput('');
    setEmailInput('');
    setMessageInput('');
  }

  return (
    <section className="contact-four py-120 rpy-100 bg-lighter">
      <div className="container">
        <div className="contact-inner-four box-shadow p-35 rpx-25 br-5 bg-white">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-section-form px-15 rpx-0 rmb-50 wow fadeInUp delay-0-2s animated">
                {messageSent && <div className="alert alert-success">Message sent!</div>}
                <div className="section-title mb-20">
                  <h2>Get In Touch</h2>
                </div>
                <form
                  id="contact-form"
                  className="contact-form"
                  name="contact-form"
                  action="#"
                  method="post"
                  onSubmit={onContactFormHandler}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Your name*</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={nameInput}
                          onChange={(event) => setNameInput(event.target.value)}
                          className="form-control"
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="number">Your phone number</label>
                        <input
                            type="text"
                            id="number"
                            name="number"
                            value={phoneInput}
                            onChange={(event) => setPhoneInput(event.target.value)}
                            className="form-control"
                            placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">Your email address*</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={emailInput}
                          onChange={(event) => setEmailInput(event.target.value)}
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-control"
                          rows="4"
                          placeholder="Let us know what you need."
                          value={messageInput}
                          onChange={(event) => setMessageInput(event.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <p>No unsolicited services or offers</p>
                      <button type="submit" className="theme-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="contact-image-number style-two bgs-cover overlay wow fadeInUp delay-0-4s animated"
                style={{
                  backgroundImage: `url(${background})`,
                  visibility: "visible",
                  animationName: "fadeInUp",
                }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="list-style-three light">
                    <li>
                      for more information about our services
                    </li>
                    <li>
                      to talk about your IT challenges
                    </li>
                    <li>
                      to get input for your IT strategy
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
