import React, { useEffect } from "react";
import logo2 from "../../../../assets/images/logos/logo-two.png";
import logo from "../../../../assets/images/logos/logo.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";

function HeaderHomeOne() {
  useEffect(() => {
    StickyMenu(".main-header");
  });

  return (
    <>
      <MobileHeaderCom logo={logo2} />
      <div className="header-reserved" />
      <header className="main-header header-one">
        <div className="header-upper bg-white">
          <div className="container-fluid clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer bg-blue py-30 px-85">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" />
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <Navigation className="nav-home-one" />
                <div className="menu-btn">
                  <a href="https://ytec.homerun.co/" rel="noreferrer" target="_blank" className="theme-btn">
                    Careers
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeOne;
