import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeTwo from "../partials/Footers/FooterHomeTwo";
import HeaderHomeOne from "../partials/Headers/HeaderHomeOne/index";
import AboutSection from "./AboutSection";
import FeatureSection from "./FeatureSection";
import Hero from "./Hero";
import ServiceSection from "./ServiceSection";
import Customers from './Customers';

function HomeOne() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <HeaderHomeOne />
      <Hero />
      <ServiceSection />
        <Customers />
      <AboutSection />
      <FeatureSection />
      {/* <NewsSection blogs={blogs} /> */}
      <FooterHomeTwo />
      <BacktoTopCom />
    </div>
  );
}

export default HomeOne;
