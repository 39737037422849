import React from "react";
import background from "../../assets/images/hero/ytec.jpg";

function Hero() {
  return (
    <section
      className="hero-section overlay bgs-cover pt-200 pb-150"
      style={{ backgroundImage: `url(${background})`, backgroundPosition: 'top' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9">
            <div className="hero-content text-center text-white">
              <span className="sub-title d-block wow fadeInUp delay-0-2s">
                EMPOWERING YOUR AMBITION
              </span>
              <h1 className="wow fadeInUp delay-0-4s mt-20">
                We solve mission critical IT problems and facilitate fast paced growth.
              </h1>
              <div className="hero-btn mt-35 wow fadeInUp delay-0-6s">
                <a href="/our-ideal-customer" className="theme-btn">
                  Work with us
                </a>
                <a href="https://ytec.homerun.co/" rel="noreferrer" target="_blank" className="theme-btn style-three" style={{marginLeft: '20px'}}>
                  Work for us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
