import axios from 'axios';
import Cookies from 'cookies-ts';

const cookies = new Cookies();

const http = axios.create({
    timeout: 6000,
    withCredentials: true,
});

http.interceptors.request.use(request => {
    const csrf = cookies.get('csrftoken') ?? '';

    if (request.headers) {
        request.headers['X-CSRFToken'] = csrf;
    } else {
        request.headers = {
            'X-CSRFToken': csrf
        };
    }

    return request;
});

export default http;