import React from "react";
import Background from "../../../../assets/images/footer/footer-bg-map.png";
import logo from "../../../../assets/images/logos/logo.png";

export default function FooterHomeTwo({ className }) {
  return (
    <footer
      className={`main-footer footer-two bgs-cover text-white ${
        className || ""
      }`}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="container">
        <div className="footer-top pt-75">
          <ul className="contact-info">
            <li>
              <i className="fas fa-phone-alt"></i>
              <div className="content">
                <span>Existing customer</span>
                <h5>
                  contact through the known channels (email, slack, phone)
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <div className="content">
                <span>New customer</span>
                <h5>
                  <a href="mailto:hello@ytec.nl">hello@ytec.nl</a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-briefcase"></i>
              <div className="content">
                <span>Work for us</span>
                <h5>
                  <a href="mailto:jobs@ytec.nl">jobs@ytec.nl</a>
                </h5>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-widget-area pt-85 pb-20">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                <h4 className="footer-title">Post Address</h4>
                <ul className="contact-info mt-20">
                  <li>
                    <i className="fas fa-envelope"></i>
                    <span>
                      Postbus 7094 <br />
                      9701JB Groningen
                    </span>
                  </li>
                </ul>
                <h4 className="footer-title mt-30">Visit Address</h4>
                <ul className="contact-info mt-20 mb-60">
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    <span>
                      Neutronstraat 2B <br />
                      9743AM Groningen
                    </span>
                  </li>
                </ul>
                <div className="footer-logo">
                  <a href="/">
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget about-widget">
                <h4 className="footer-title">Office times</h4>
                <ul className="contact-info mt-20">
                  <li>
                    <i className="fas fa-envelope"></i>
                    <span>
                      Monday - Friday <br />
                      09:00 - 17:30
                    </span>
                  </li>
                </ul>
                <h4 className="footer-title mt-30">Company Information</h4>
                <ul className="contact-info mt-20">
                  <li>
                    <i className="fas fa-building"></i>
                    <span>
                      KvK: 01148001<br />
                      BTW: NL820565817B01
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Page Links</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/our-ideal-customer">Work With Us</a>
                  </li>
                  <li>
                    <a href="/team">Team</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  {/*<li>*/}
                  {/*  <a href="/portfolio">portfolio</a>*/}
                  {/*</li>*/}
                  <li>
                    <a href="/our-ideal-customer#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a href="https://www.linkedin.com/company/ytec" rel="noreferrer" target="_blank">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="http://twitter.com/ytecnl" rel="noreferrer" target="_blank">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <p>Copyright 2022 Ytec All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
