import React from "react";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-240 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="section-title text-center mb-35">
              <span className="sub-title">CORE SERVICES</span>
              <h2>Our development skill-sets</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="flaticon flaticon-art"></i>
              </div>
              <div className="feature-content">
                <h5>Front-end development</h5>
                <p>
                  React, mostly for dashboards and B2B environments.<br/><br/>
                </p>
                {/*<a href="service-details" className="learn-more">*/}
                {/*  Read More <i className="fas fa-arrow-right"></i>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-4s">
              <div className="icon">
                <i className="flaticon flaticon-cloud-computing-1"></i>
              </div>
              <div className="feature-content">
                <h5>Integrations</h5>
                <p>
                  Integrating and interacting with API's. Using RESTful API's and GraphQL
                </p>
                {/*<a href="/service-details" className="learn-more">*/}
                {/*  Read More <i className="fas fa-arrow-right"></i>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-6s">
              <div className="icon">
                <i className="flaticon flaticon-development-3"></i>
              </div>
              <div className="feature-content">
                <h5>Web development</h5>
                <p>
                  We are specialized in back-end web development work, with Django and Laravel.
                </p>
                {/*<a href="/service-details" className="learn-more">*/}
                {/*  Read More <i className="fas fa-arrow-right"></i>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="flaticon flaticon-analysis-1"></i>
              </div>
              <div className="feature-content">
                <h5>High performance infra</h5>
                <p>
                  MQTT, AWS<br/><br/><br/>
                </p>
                {/*<a href="/service-details" className="learn-more">*/}
                {/*  Read More <i className="fas fa-arrow-right"></i>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-0s">
              <div className="icon">
                <i className="flaticon flaticon-web-development"></i>
              </div>
              <div className="feature-content">
                <h5>Software engineering</h5>
                <p>
                  C++, Python, PHP(Laravel, Lumen), C#(.NET)<br/><br/>
                </p>
                {/*<a href="/service-details" className="learn-more">*/}
                {/*  Read More <i className="fas fa-arrow-right"></i>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-2s">
              <div className="icon">
                <i className="flaticon flaticon-plan"></i>
              </div>
              <div className="feature-content">
                <h5>Mobile applications</h5>
                <p>
                  We use Flutter and Dart to make cross-platform mobile applications.
                </p>
                {/*<a href="/service-details" className="learn-more">*/}
                {/*  Read More <i className="fas fa-arrow-right"></i>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="feature-btn text-center mt-20">
          <a href="/services" className="theme-btn">
            view all services
          </a>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
