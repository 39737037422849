import React from "react";
import Background from "../../assets/images/counter/counter-bg.jpg";
import AboutUs from '../../assets/images/about/about.png';
import CounterUp from "../helpers/CounterUp";

function AboutSection({ className, funFact = true }) {
  return (
    <section
      className={`about-section bg-light-black pt-120 rpt-100 ${
        className || ""
      }`}
      id="about-section "
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-image-shape rmb-70 wow fadeInLeft delay-0-2s">
              <img
                src={AboutUs}
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content text-white pr-70 rpr-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">Years of experience</span>
                <h2>
                  Helping customers grow and improve
                </h2>
              </div>
              <p>
                We are platform and product independent, We won't push any products on you. Based on your strategy we will look for the most durable or the most cost efficient solution.
                We integrate with many of the industry standard technologies, platforms and tools.
              </p>
              <p className="mt-15 mb-0 font-weight-bold">
                Some of the fields we work in:
              </p>
              <ul className="list-style-one">
                <li><div className="list-item"><h1>Warehousing</h1> <span>Streamlining and automating, stock level, planning, product tracking (serial numbers).</span></div></li>
                <li><div className="list-item"><h1>Logistics</h1> <span>Track shipments, lead times, inter branch transfers, connect for forwarders.</span></div></li>
                <li><div className="list-item"><h1>E-commerce</h1> <span>Integrate e-commerce platforms, b2b order systems, distributor platforms, payment methods.</span></div></li>
                <li><div className="list-item"><h1>Information management</h1> <span>We build and extend b2b information platforms with members, events, e-learning.</span></div></li>
                <li><div className="list-item"><h1>ERP integration</h1> <span>We optimize and extend employee planning, order and invoice flows.</span></div></li>
              </ul>
            </div>
          </div>
        </div>
        {funFact && (
          <div
            className="fact-counter-inner br-5 px-25 pt-80 pb-30 text-white text-center"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-2s">
                  <span
                    className="count-text plus-b"
                    data-speed="5000"
                    data-stop="17"
                  >
                    <CounterUp endValue={17} sectionSelect="about-section " />
                  </span>
                  <p>Years in business</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-4s">
                  <span className="count-text">
                    <CounterUp endValue={17} sectionSelect="about-section " />
                  </span>
                  <p>Core team members</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-6s">
                  <span
                    className="plus-b count-text k"
                    data-speed="5000"
                    data-stop="12"
                  >
                    <CounterUp endValue={100} sectionSelect="about-section " />
                  </span>
                  <p>Active users on our B2B platforms</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="success-item wow fadeInUp delay-0-8s">
                  <span
                    className="count-text plus-b M"
                    data-speed="5000"
                    data-stop="500"
                  >
                    <CounterUp endValue={500} sectionSelect="about-section " />
                  </span>
                  <p>Processing annual revenue</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AboutSection;
