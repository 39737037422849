import { Route, Routes } from "react-router-dom";
import AboutFour from "./components/AboutPages/Aboutfour/index";
// import Blog from "./components/BlogPages/Blog";
// import BlogDetails from "./components/BlogPages/BlogDetails";
// import Contact from "./components/Contact/index";
import FourZeroFour from "./components/FourZeroFour/index";
import HomeOne from "./components/HomeOne";
import ProtfolioDetails from "./components/PortfolioPages/PortfolioDetails";
import PortfolioFour from "./components/PortfolioPages/PortfolioFour";
import PortfolioOne from "./components/PortfolioPages/PortfolioOne";
import PortfolioThree from "./components/PortfolioPages/PortfolioThree";
import PortfolioTwo from "./components/PortfolioPages/PortfolioTwo";
import ServiceDetails from "./components/ServicePages/ServiceDetails/index";
import TeamTwo from "./components/TeamPages/TeamTwo";

export default function Routers() {
  return (
    <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/home" element={<HomeOne />} />
      <Route exact path="/our-ideal-customer" element={<AboutFour />} />
      <Route path="/services/*" element={<ServiceDetails />} />
      <Route exact path="/portfolio-one" element={<PortfolioOne />} />
      <Route exact path="/portfolio-two" element={<PortfolioTwo />} />
      <Route exact path="/portfolio-three" element={<PortfolioThree />} />
      <Route exact path="/portfolio-four" element={<PortfolioFour />} />
      <Route exact path="/portfolio-details" element={<ProtfolioDetails />} />
      <Route exact path="/team" element={<TeamTwo />} />
      {/*<Route exact path="/blog" element={<Blog />} />*/}
      {/*<Route exact path="/blog/blog-details" element={<BlogDetails />} />*/}
      {/*<Route exact path="/contact" element={<Contact />} />*/}
        <Route path="/*" element={<FourZeroFour />} />
    </Routes>
  );
}
