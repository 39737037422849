import { Link, useNavigate } from "react-router-dom";

function Navigation({ className }) {
  const navigate = useNavigate();

  const scrollToContact = () => {
    navigate('/our-ideal-customer#contact');
  }

  return (
    <>
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/home">
              Home
            </Link>
          </li>
          <li>
            <Link to="#">
              About <i className="fa fa-angle-down" />
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/our-ideal-customer">Work With Us</Link>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/services">
              Services
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link to="/portfolio">*/}
          {/*    Portfolio*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link to="#">*/}
          {/*    Blog*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li onClick={scrollToContact}>
            <a>Contact</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
