import {useState} from 'react';
import {useParams} from 'react-router-dom';

export default function MainSection() {
  const params = useParams();
  const [selectedService, setSelectedService] = useState(params['*'] === '' ? 'consultancy' : params['*']);

  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                  <li className={`${selectedService === 'consultancy' ? 'active' : ''}`}>
                    <a onClick={() => setSelectedService('consultancy')}>Consultancy</a>
                  </li>
                  <li className={`${selectedService === 'integrations' ? 'active' : ''}`}>
                    <a onClick={() => setSelectedService('integrations')}>Integrations</a>
                  </li>
                  <li className={`${selectedService === 'development' ? 'active' : ''}`}>
                    <a onClick={() => setSelectedService('development')}>Development</a>
                  </li>
                  <li className={`${selectedService === 'automation' ? 'active' : ''}`}>
                    <a onClick={() => setSelectedService('automation')}>Automation</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              {selectedService === 'consultancy' && (
                  <>
                    <h2>Consultancy</h2>
                    <p>
                      We support organizations in various stages. It often starts with an analysis of the current situation, where we look at the processes and systems that are in place and how they are aligned with the organization’s goals and strategy. We advise on improving the IT strategy, the deployment of an existing strategy and on how to optimize and automate business processes with tools and integrations.
                    </p>
                    <ul className="list-style-three pt-15 pb-30">
                      <li>IT strategy consulting</li>
                      <li>Supporting C-Level executives and management teams</li>
                      <li>Strategy deployment</li>
                      <li>Business process analysis and optimization</li>
                    </ul>
                  </>
              )}

              {selectedService === 'integrations' && (
                  <>
                    <h2>Integrations</h2>
                    <p>
                      Usually we enter an existing IT landscape with various tools and systems in place. Sometimes the complexity of the situation has increased over the years because of fast growth and tech debt has accumulated. Sometimes workarounds or a lot of manual steps are involved in certain processes.
                      Here we can often clean up and streamline components with integrations. We connect old tools with new software and integrate with industry leading systems using widely used and open standards.
                    </p>
                    <ul className="list-style-three pt-15 pb-30">
                      <li>From monoliths to microservices architecture</li>
                      <li>Domain Driven Design</li>
                      <li>REST, GraphQL</li>
                    </ul>
                  </>
              )}

              {selectedService === 'development' && (
                  <>
                    <h2>Development</h2>
                    <p>
                      Sometimes existing tools cant do the job. Its a delicate process of figuring this out and a lot of organizations tend to dive into custom development too early. Often times this is reasoned by: what we do is utterly unique, or: its much cheaper to develop it in house with our own people.
                      Both arguments can often be challenged.
                      But sometimes the demand for development really exists, either for <a className="text-primary" onClick={() => setSelectedService('integrations')}>integrations</a> or really because there is a way to do it better or faster or at all than with existing off the shelf software.

                      in those case we design and build software solutions that of course still integrate with the rest of the IT landscape.
                    </p>
                    <ul className="list-style-three pt-15 pb-30">
                      <li>Unique niche software</li>
                      <li>Competitive advantage</li>
                      <li>Software that is (close to) the core business</li>
                    </ul>
                  </>
              )}

              {selectedService === 'automation' && (
                  <>
                    <h2>Automation</h2>
                    <p>
                      Automation is the key to scaling a business or organization. Process analysis comes first, improving the processes and behavior of the people involved. sometimes defining proper roles and responsibilities solves already a lot of manual problems. When properly examined and with proven processes we can look into automation.
                      for example
                    </p>
                    <ul className="list-style-three pt-15 pb-30">
                      <li>Automated order processing based on all kind of business rules</li>
                      <li>Checks and updates of dataflows (lead and shipping information)</li>
                      <li>Communication towards suppliers or customers (like in case of changing stock levels)</li>
                      <li>Processing of financial flows of approval and consistency checks</li>
                    </ul>
                  </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
